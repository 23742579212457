import type { Route } from './+types/Login';
import {
  Form,
  Link,
  redirect,
  useActionData,
  useNavigation,
  useSearchParams,
} from 'react-router';
import { createUserSession } from '~/utils/session.server';
import { validatePassword } from '~/lib/utils';
import { useEffect, useState } from 'react';
import { useToast } from '~/hooks/use-toast';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '~/components/ui/card';
import { Label } from '~/components/ui/label';
import { Input } from '~/components/ui/input';
import { Eye, EyeOff } from 'lucide-react';
import { Button } from '~/components/ui/button';
import { publicAPIClient } from '~/utils/publicApiClient.server';

export function meta({}: Route.MetaArgs) {
  return [
    { title: 'Login | DViO One' },
    { name: 'description', content: 'Sign In to DViO ONE!' },
  ];
}
export async function action({ request }: Route.ActionArgs) {
  const formData = Object.fromEntries(await request.formData());
  const username = formData.email;
  const password = formData.password;
  const redirectUrl = formData.redirectUrl;
  const validatedPasswordError = validatePassword(password as string);
  if (validatedPasswordError) {
    return { errors: validatedPasswordError };
  }
  try {
    const data = await publicAPIClient<{
      access_token?: string;
      expires_in?: number;
      token_type?: string;
      detail?: string;
    }>(
      '/auth/login',
      'POST',
      { username, password },
      'application/x-www-form-urlencoded',
    );
    if (data.access_token) {
      const sessionHeader = await createUserSession(data.access_token, request);
      return redirect(
        typeof redirectUrl === 'string' ? redirectUrl : '/dashboard',
        {
          headers: {
            'Set-Cookie': sessionHeader,
          },
        },
      );
    } else {
      return { error: data?.detail || 'Login failed' };
    }
  } catch (error) {
    return { error: 'Login failed' };
  }
}

export default function Page() {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [error, setError] = useState<string>('');
  const actionData = useActionData();
  const navigation = useNavigation();
  const { toast } = useToast();
  let [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (actionData && actionData.error) {
      setError('');
      setIsLoading(false);
      toast({
        title: <span className="first-letter:capitalize">Failed</span>,
        duration: 3000,
        variant: 'destructive',
        description: actionData.error,
      });
    } else if (actionData && actionData.errors) {
      setError(actionData.errors);
      setIsLoading(false);
    } else {
      setError('');
      setIsLoading(false);
    }
  }, [actionData]);

  return (
    <Card className="w-full max-w-md">
      <CardHeader>
        <CardTitle className="text-2xl">Login</CardTitle>
        <CardDescription>
          Enter your email below to login to your account
        </CardDescription>
      </CardHeader>

      <CardContent>
        <Form
          method="post"
          className="grid gap-4"
          onSubmit={() => setIsLoading(true)}
        >
          <input
            type="hidden"
            name="redirectUrl"
            value={searchParams.get('redirect') || '/dashboard'}
          />
          <div className="grid gap-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              name="email"
              autoFocus
              type="email"
              tabIndex={1}
              required
            />
          </div>
          <div className="grid gap-2 ">
            <div className="flex items-center">
              <Label htmlFor="password">Password</Label>
              <Link
                to="/forgot-password"
                className="ml-auto inline-block text-sm underline"
              >
                Forgot your password?
              </Link>
            </div>
            <div className="relative">
              <Input
                id="password"
                name="password"
                type={isPasswordHidden ? 'password' : 'text'}
                tabIndex={2}
                required
              ></Input>
              <button
                type="button"
                className="absolute inset-y-0 right-5 top-0 flex items-center text-sm leading-5"
                onClick={() => setIsPasswordHidden(!isPasswordHidden)}
                aria-label={
                  isPasswordHidden ? 'Show password' : 'Hide password'
                }
              >
                {isPasswordHidden ? <Eye /> : <EyeOff />}
              </button>
            </div>
          </div>
          {error && (
            <div className="text-red-500 text-xs lg:text-sm">*{error}</div>
          )}
          {actionData?.error && (
            <p className="text-red-500 text-xs lg:text-sm">
              *{actionData.error}
            </p>
          )}
          <Button
            type="submit"
            className="w-full"
            disabled={navigation.state != 'idle'}
            aria-label="Login"
            tabIndex={3}
          >
            {isLoading ? 'Signing you in...' : 'Login'}
          </Button>
        </Form>
      </CardContent>
    </Card>
  );
}
